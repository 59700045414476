* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e2e2e;
  color: whitesmoke;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 30rem;
  padding: 1.5rem 1rem;
  border: 1px solid grey;
  overflow: auto;
  border-radius: 0.2rem;
  margin: 10px;
}
.wrapper {
  width: 100%;
  font-size: 0.8rem;
  padding: 1rem;
}
.formTodo {
  display: flex;
  justify-content: space-between;
}

.formTodo .inputTodo {
  width: 85%;
  padding: 0 0.8rem 0 1rem;
  border: none;
  border-radius: 0.2rem;
}
.submit-btn {
  padding: 0.5rem;
  /* margin: 0 0.3rem; */
  border: none;
  border-radius: 0.2rem;
}
.btn {
  padding: 0.5rem;
  margin: 0 0.3rem;
  border: none;
  border-radius: 0.2rem;
}
.groupTodo {
  list-style: none;
  margin-top: 1.5rem;
  /* padding: 0 0.5rem; */
}
.singleTodo {
  display: flex;
  align-items: center;
  background-color: #5dadf8;
  border-radius: 0.2rem;
  padding: 0.3rem 0.3rem 0.3rem 1rem;
  margin: 1rem 0;
}
.todoText {
  flex: 1;
  font-size: 1.1rem;
  color: whitesmoke;
}
